// @ts-nocheck
/* eslint-enable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import './style.scss';
import { store } from 'app/client/utils/credentials';
import AuthActions from 'app/shared/flux/actions/AuthActions';
import browserUtils from 'app/client/utils/browserUtils';
import Button from 'app/shared/core/Button';
import Center from 'app/shared/modules/Center';
import Divider from 'app/shared/modules/Divider';
import Input from 'app/shared/core/Input';
import Linker from 'app/shared/modules/Linker';
import PromptBreadcrumb from 'app/shared/modules/auth/PromptBreadcrumb';
import Row from 'app/shared/core/Row';
import Section from 'app/shared/modules/Section';
import Title from 'app/shared/modules/Title';
import userUtils from 'app/shared/utils/userUtils';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('modules/auth');

class PromptPassword extends React.Component {
  static defaultProps = {
    handleLoginSuccess: () => {},
    handleForgotPassword: null,
    header: null,
    makeVisible: () => {},
    showTitle: true,
    shouldRedirect: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMsg: '',
      loading: false,
      userPassword: '',
    };
  }

  handleResetPassword = (e) => {
    e.preventDefault();
    const { handleForgotPassword } = this.props;
    if (handleForgotPassword) {
      handleForgotPassword();
    } else {
      this.props.makeVisible('promptForgotPassword');
    }
  };
  handleSubmitPassword = (e) => {
    e.preventDefault();
    const { dispatch, email, handleLoginSuccess, isFavoriteOrHideAttempt, shouldRedirect } = this.props;
    const { userPassword } = this.state;

    if (userPassword) {
      this.setState({
        loading: true,
        errorMsg: '',
      });
      dispatch(AuthActions.login.emailAndPassword(email, userPassword))
        .then((data) => {
          if (data.error) {
            this.setState({
              loading: false,
              errorMsg: data.error,
            });
          } else if (data.success && data.loggedIn) {
            store(email, userPassword);
            this.setState({
              loading: false,
            });
            dispatch(AuthActions.login.success(data));
            dispatch(AuthActions.login.checkLoginPageRedirect(isFavoriteOrHideAttempt || !shouldRedirect));
          } else if (!data.loggedIn || data.status === 'INCORRECT_PASSWORD') {
            this.setState({
              loading: false,
              errorMsg: 'We did not find a user matching these credentials. Please try again.',
              userPassword: '',
            });
          }
          return data;
        })
        .then((data) => {
          if (!data.loggedIn || data.status === 'INCORRECT_PASSWORD') {
            return;
          } else if (handleLoginSuccess) {
            handleLoginSuccess();
          } else {
            userUtils.successLoginRedirect();
          }
        })
        .catch((err) => {
          logError({ error: err, context: 'Error in AuthActions.login.emailAndPassword' });
          this.setState({
            loading: false,
            errorMsg: 'Error logging in. Please try again.',
          });
        });
    } else {
      this.setState({ errorMsg: 'Please enter a password.' });
    }
  };

  handleTextInput = (e) => {
    this.setState({ userPassword: e.target.value });
  };

  render() {
    const { email, header, showTitle, onBackClick } = this.props;
    const { errorMsg, loading, userPassword } = this.state;
    const CustomHeader = header;
    let hpxStyle = null;
    let hpxErrorMessage = null;

    if (errorMsg) {
      hpxErrorMessage = errorMsg;
      hpxStyle = 'error';
    }

    return (
      <div className="PromptPassword">
        {!CustomHeader && <PromptBreadcrumb onBackClick={onBackClick} />}
        <Section>
          {CustomHeader ? (
            <CustomHeader data={email} />
          ) : (
            <Fragment>{showTitle && <Title size="lg">Enter password</Title>}</Fragment>
          )}
          <form>
            <Row>
              <Input
                autoComplete="current-password"
                autoFocus={browserUtils.screen.width() && browserUtils.screen.width() !== 'sm'}
                className="login-input login-input-pw"
                help={hpxErrorMessage}
                hpxStyle={hpxStyle}
                id="password"
                label="Password"
                maxLength={50}
                name="password"
                onChange={this.handleTextInput}
                required
                type="password"
                value={userPassword}
              />
            </Row>
            <Row>
              <Button full onClick={this.handleSubmitPassword} disabled={loading}>
                {loading ? 'Loading...' : 'Sign in'}
              </Button>
            </Row>
          </form>
          <Divider />
          <Row>
            <Center>
              <Linker useButton linkType="default" onClick={this.handleResetPassword}>
                Forgot your password?
              </Linker>
            </Center>
          </Row>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFavoriteOrHideAttempt: state.app.popupModal.data.shouldNotRedirect,
});

export default connect(mapStateToProps)(PromptPassword);
