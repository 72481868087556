// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import Button from 'app/shared/core/Button';
import Checkbox from 'app/shared/core/Checkbox';
import constants from 'app/shared/constants/ConstantsBundle';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import NotificationActions from 'app/shared/flux/actions/NotificationActions';
import PromptBreadcrumb from 'app/shared/modules/auth/PromptBreadcrumb';
import Row from 'app/shared/core/Row';
import Section from 'app/shared/modules/Section';
import './style.scss';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';
import userUtils from 'app/shared/utils/userUtils';

const StyledDarkText = styled(Text)`
  color: ${colors['$hpx-grey-600']};
`;
const StyledBoldText = styled(Text)`
  font-weight: 700;
`;
const StyledBoldSpan = styled.span`
  font-weight: 700;
`;
const StyledCenterTitle = styled(Title)`
  text-align: center;
`;
function PromptCasl({
  showTitle,
  showDescription,
  email,
  onBackClick = () => {},
  dispatch,
  onHidePopup = () => {},
  showBreadcrumb = true,
}) {
  const [acceptedNotifications, setAcceptedNotifications] = useState(false);

  useEffect(() => {
    dispatch(NotificationActions.email.updateEmailOptIn(acceptedNotifications, 'signUp')).catch((error) => {
      dispatch(
        ErrorActions.errorHandler({
          error,
          errorLocation: 'PromptCasl#updateEmailOptIn',
          errorClass: 'PromptCasl',
        }),
      );
    });
  }, [acceptedNotifications, dispatch]);

  function handleCheckboxChange() {
    setAcceptedNotifications(!acceptedNotifications);
  }

  function handleSubmit() {
    dispatch({
      type: constants.HIDE_POPUP_MODAL,
    });
    onHidePopup();
    userUtils.successLoginRedirect();
  }

  return (
    <div>
      {showBreadcrumb && <PromptBreadcrumb onBackClick={onBackClick} />}
      {showTitle && (
        <StyledCenterTitle htmlTag="div" size="lg">
          Sign in / register
        </StyledCenterTitle>
      )}
      {showDescription && (
        <StyledDarkText>Sign in or register to keep track of your favorite listings and more!</StyledDarkText>
      )}
      <Section>
        <StyledDarkText htmlTag="div" size="sm" className="PromptCasl-email-label">
          Email
        </StyledDarkText>
        <Row>
          <StyledBoldText htmlTag="div" size="sm">
            {email}
          </StyledBoldText>
        </Row>
        <div className="PromptCasl-checkbox-container">
          <Checkbox onChange={handleCheckboxChange} className="PromptCasl-checkbox" checked={acceptedNotifications} />
          <Text size="sm">
            <StyledBoldSpan>One more thing!</StyledBoldSpan> By checking this box, you consent to receive emails from
            HotPads, including newsletters, alerts, updates, invitations, promos, and other news and notifications.
          </Text>
        </div>
        <Row>
          <Button onClick={handleSubmit} full>
            Complete sign up
          </Button>
        </Row>
      </Section>
    </div>
  );
}

export default connect()(PromptCasl);
