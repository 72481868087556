import React from 'react';
import loadable from '@loadable/component';

const PromptForgotPassword = loadable(
  () =>
    import(
      /* webpackChunkName: "PromptForgotPassword.loadable" */ 'app/shared/modules/auth/PromptForgotPasswordV1/component'
    ),
);

class LoadablePromptForgotPassword extends React.Component {
  render() {
    return <PromptForgotPassword {...this.props} />;
  }
}

export default LoadablePromptForgotPassword;
